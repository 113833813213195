
import { IMAGES_HOSTS } from '@/services/constants';

export default {
  name: 'ImgLoader',

  props: {
    imgPath: {
      type: String,
      required: true,
    },
    modifiers: {
      type: Object,
      default: () => ({}),
    },
    altImg: {
      type: String,
      default: '',
    },
  },

  computed: {
    cdnUrl() {
      return process.env.NUXT_ENV_APP_ENV !== 'production' ? IMAGES_HOSTS.STAGING : IMAGES_HOSTS.PRODUCTION;
    },
    imgSrc() {
      return `${this.cdnUrl}/images/${this.imgPath}`;
    },
    imgModifiers() {
      return {
        f: 'auto',
        ...this.modifiers,
      };
    },
  },
};
